import { Component, OnInit, OnDestroy } from "@angular/core";

@Component({
    selector: "app-timer",
    templateUrl: "./timer.component.html",
    styleUrls: ["./timer.component.scss"],
})
export class TimerComponent implements OnDestroy {
    counter: number = 0;
    timerRef: any;
    running: boolean = false;

    startTimer() {
        this.running = true;
        const startTime = Date.now() - (this.counter || 0);
        this.timerRef = setInterval(() => {
            this.counter = Date.now() - startTime;
        });
    }

    stopTimer() {
        this.running = false;
        clearInterval(this.timerRef);
    }

    clearTimer() {
        this.running = false;
        this.counter = 0;
        clearInterval(this.timerRef);
    }

    ngOnDestroy() {
        clearInterval(this.timerRef);
    }
}
