<div class="container">
    <div [formGroup]="sizeForm" class="top-bar">
        <mat-form-field class="input">
            <mat-label>Height</mat-label>
            <input type="number" matInput formControlName="height" />
            <mat-error *ngIf="sizeForm.controls.height.hasError('required')">
                <strong>required</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="input">
            <mat-label>Width</mat-label>
            <input type="number" matInput formControlName="width" />
            <mat-error *ngIf="sizeForm.controls.width.hasError('required')">
                <strong>required</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="input">
            <mat-label>Mines</mat-label>
            <input type="number" matInput formControlName="mines" />
            <mat-error *ngIf="sizeForm.controls.width.hasError('required')">
                <strong>required</strong>
            </mat-error>
        </mat-form-field>

        <button
            mat-raised-button
            color="primary"
            [disabled]="!sizeForm.valid"
            (click)="newGame()"
        >
            New game
        </button>

        <app-timer #timer></app-timer>
    </div>

    <table id="table" (contextmenu)="$event.preventDefault()">
        <tr *ngFor="let row of board.cells; index as y">
            <td
                *ngFor="let cell of row; index as x"
                [ngClass]="{ clear: cell.status === 'clear' }"
                (mousedown)="
                    !this.gameOver ? handleMouseDown($event, cell) : ''
                "
                (mouseover)="
                    !this.gameOver ? handleMouseOver($event, cell) : ''
                "
                (mouseleave)="
                    !this.gameOver ? handleMouseLeave($event, cell) : ''
                "
                (mouseup)="!this.gameOver ? handleMouseUp($event, cell) : ''"
                (contextmenu)="$event.preventDefault()"
            >
                <!-- <div>Status: {{ cell.status }}</div> -->
                <div
                    class="number-of-mines"
                    [ngClass]="'mine-' + cell.proximityMines"
                    *ngIf="cell.status === 'clear' && !cell.mine"
                >
                    {{ cell.proximityMines > 0 ? cell.proximityMines : "" }}
                </div>
                <div *ngIf="cell.status === 'flag'">
                    <svg class="flag-svg" viewBox="0 0 24 24">
                        <path
                            fill="#000000"
                            d="M14.4,6L14,4H5V21H7V14H12.6L13,16H20V6H14.4Z"
                        />
                    </svg>
                </div>
                <div *ngIf="cell.mine && cell.status === 'clear'">
                    <svg class="mine-svg" viewBox="0 0 24 24">
                        <path
                            fill="#000000"
                            d="M23,13V11H19.93C19.75,9.58 19.19,8.23 18.31,7.1L20.5,4.93L19.07,3.5L16.9,5.69C15.77,4.81 14.42,4.25 13,4.07V1H11V4.07C9.58,4.25 8.23,4.81 7.1,5.69L4.93,3.5L3.5,4.93L5.69,7.1C4.81,8.23 4.25,9.58 4.07,11H1V13H4.07C4.25,14.42 4.81,15.77 5.69,16.9L3.5,19.07L4.93,20.5L7.1,18.31C8.23,19.19 9.58,19.75 11,19.93V23H13V19.93C14.42,19.75 15.77,19.19 16.9,18.31L19.07,20.5L20.5,19.07L18.31,16.9C19.19,15.77 19.75,14.42 19.93,13H23M12,8A4,4 0 0,0 8,12H6A6,6 0 0,1 12,6V8Z"
                        />
                    </svg>
                </div>
            </td>
        </tr>
    </table>
</div>
