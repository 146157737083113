import { Cell } from "./cell";

const PEERS = [
    [-1, -1],
    [-1, 0],
    [-1, 1],
    [0, -1],
    [0, 1],
    [1, -1],
    [1, 0],
    [1, 1],
];

export class Board {
    cells: Cell[][] = [];

    private remainingCells = 0;
    private mineCount = 0;
    private gameStarted = false;
    private mines = 0;
    private rows = 0;
    private columns = 0;

    constructor(rows: number, columns: number, mines: number) {
        for (let r = 0; r < rows; r++) {
            this.cells[r] = [];
            for (let c = 0; c < columns; c++) {
                this.cells[r][c] = new Cell(r, c);
            }
        }

        this.mines = mines;
        this.rows = rows;
        this.columns = columns;
    }

    getPeerCells(cell: Cell): Cell[] {
        const peerCells: Cell[] = [];

        for (const peer of PEERS) {
            const peerRow = cell.row + peer[0];
            const peerColumn = cell.column + peer[1];

            if (
                peerRow >= 0 &&
                peerRow < this.rows &&
                peerColumn >= 0 &&
                peerColumn < this.columns
            ) {
                const peerCell = this.cells[peerRow][peerColumn];

                peerCells.push(peerCell);
            }
        }

        return peerCells;
    }

    assignMines(mines: number, cell: Cell) {
        const reservedCells: Cell[] = [];

        for (const peer of PEERS) {
            const peerRow = cell.row + peer[0];
            const peerColumn = cell.column + peer[1];

            if (
                peerRow >= 0 &&
                peerRow < this.rows &&
                peerColumn >= 0 &&
                peerColumn < this.columns
            ) {
                const peerCell = this.cells[peerRow][peerColumn];

                reservedCells.push(peerCell);
            }
        }

        let i = 0;

        while (i < mines) {
            const randomCell = this.getRandomCell();
            if (
                !reservedCells.find((el) => el == randomCell) &&
                cell != randomCell
            ) {
                if (!randomCell.mine) {
                    randomCell.mine = true;
                    i++;
                }
            }
        }

        this.countMines(this.rows, this.columns);
    }

    countMines(rows: number, columns: number) {
        for (let r = 0; r < rows; r++) {
            for (let c = 0; c < columns; c++) {
                let adjacentMines = 0;
                for (const peer of PEERS) {
                    if (
                        this.cells[r + peer[0]] &&
                        this.cells[r + peer[0]][c + peer[1]] &&
                        this.cells[r + peer[0]][c + peer[1]].mine
                    ) {
                        adjacentMines++;
                    }
                }
                this.cells[r][c].proximityMines = adjacentMines;

                if (this.cells[r][c].mine) {
                    this.mineCount++;
                }
            }
        }
        this.remainingCells = rows * columns - this.mineCount;
    }

    getRandomCell(): Cell {
        const r = Math.floor(Math.random() * this.cells.length);
        const c = Math.floor(Math.random() * this.cells[r].length);
        return this.cells[r][c];
    }

    checkCell(cell: Cell): "gameover" | "win" | null {
        if (!this.gameStarted) {
            this.assignMines(this.mines, cell);

            this.gameStarted = true;
        }

        if (cell.status !== "open") {
            return null;
        } else if (cell.mine) {
            this.revealAll();
            return "gameover";
        } else {
            cell.status = "clear";

            if (cell.proximityMines === 0) {
                for (const peer of PEERS) {
                    if (
                        this.cells[cell.row + peer[0]] &&
                        this.cells[cell.row + peer[0]][cell.column + peer[1]]
                    ) {
                        this.checkCell(
                            this.cells[cell.row + peer[0]][
                                cell.column + peer[1]
                            ]
                        );
                    }
                }
            }

            if (this.remainingCells-- <= 1) {
                return "win";
            }
            return null;
        }
    }

    revealAll() {
        for (const row of this.cells) {
            for (const cell of row) {
                if (cell.mine) {
                    cell.status = "clear";
                }
            }
        }
    }
}
