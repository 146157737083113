import { Component, ViewChild } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { Board } from "./game/board";
import { Cell } from "./game/cell";
import { TimerComponent } from "./timer/timer.component";
@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent {
    @ViewChild("timer")
    timer: TimerComponent = new TimerComponent();

    title = "minesweeper";
    board: Board;
    rightMouseDown = false;
    leftMouseDown = false;
    gameStarted = false;
    gameOver = false;
    hoveringCell: Cell | null = null;
    height = 16;
    width = 30;
    mines = 99;

    sizeForm!: FormGroup;

    constructor(private formBuilder: FormBuilder) {
        this.board = new Board(this.height, this.width, this.mines);

        document.addEventListener("mouseup", (e) => this.handleMouseUpDoc(e));
    }

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        this.sizeForm = this.formBuilder.group({
            height: new FormControl(this.height, [Validators.required]),
            width: new FormControl(this.width, [Validators.required]),
            mines: new FormControl(this.mines, [Validators.required]),
        });

        this.sizeForm
            .get("height")
            ?.valueChanges.subscribe((val) => (this.height = val));

        this.sizeForm
            .get("width")
            ?.valueChanges.subscribe((val) => (this.width = val));

        this.sizeForm
            .get("mines")
            ?.valueChanges.subscribe((val) => (this.mines = val));
    }

    getCellValues(
        cell: Cell
    ): Array<{ r: number; c: number; htmlCell: HTMLTableCellElement }> {
        const table: HTMLTableElement | null = document.querySelector("table");

        const peerCells = this.board.getPeerCells(cell);

        const cells: Array<{
            r: number;
            c: number;
            htmlCell: HTMLTableCellElement;
        }> = [];

        if (table) {
            for (var r = 0, n = table.rows.length; r < n; r++) {
                for (var c = 0, m = table.rows[r].cells.length; c < m; c++) {
                    if (peerCells.find((el) => el.column == c && el.row == r)) {
                        cells.push({ r, c, htmlCell: table.rows[r].cells[c] });
                    } else if (cell.column == c && cell.row == r) {
                        cells.push({ r, c, htmlCell: table.rows[r].cells[c] });
                    }
                }
            }
        }

        return cells;
    }

    getCellValue(
        cell: Cell
    ): { r: number; c: number; htmlCell: HTMLTableCellElement } | null {
        const table: HTMLTableElement | null = document.querySelector("table");

        if (table) {
            for (var r = 0, n = table.rows.length; r < n; r++) {
                for (var c = 0, m = table.rows[r].cells.length; c < m; c++) {
                    if (cell.column == c && cell.row == r) {
                        return { r, c, htmlCell: table.rows[r].cells[c] };
                    }
                }
            }
        }

        return null;
    }

    handleMouseDown(e: MouseEvent, cell: Cell) {
        const peerCells: Cell[] = this.board.getPeerCells(cell);
        this.hoveringCell = cell;

        if (e.button === 2) {
            this.rightMouseDown = true;
            if (this.leftMouseDown) {
                this.getCellValues(cell).forEach((htmlCell) => {
                    const flag =
                        peerCells.find(
                            (el) =>
                                el.column == htmlCell.c && el.row == htmlCell.r
                        )?.status === "flag";
                    if (!flag) htmlCell.htmlCell.classList.add("hovering");
                });
            }
        } else if (e.button === 0) {
            this.leftMouseDown = true;

            if (cell.status !== "flag")
                this.getCellValue(cell)?.htmlCell.classList.add("hovering");

            if (this.rightMouseDown) {
                this.getCellValues(cell).forEach((htmlCell) => {
                    const flag =
                        peerCells.find(
                            (el) =>
                                el.column == htmlCell.c && el.row == htmlCell.r
                        )?.status === "flag";
                    if (!flag) htmlCell.htmlCell.classList.add("hovering");
                });
            }
        }
    }

    handleMouseUp(e: MouseEvent, cell: Cell) {
        this.getCellValues(cell).forEach((el) => {
            el.htmlCell.classList.remove("hovering");
        });

        if (e.button === 2) {
            this.rightMouseDown = false;

            if (!this.leftMouseDown) {
                this.flag(cell);
            }

            if (this.leftMouseDown) {
                this.chord(cell);
            }
        }

        if (e.button === 0) {
            this.leftMouseDown = false;

            if (!this.rightMouseDown) {
                this.checkCell(cell);
            }

            if (this.rightMouseDown) {
                this.chord(cell);
            }
        }
    }

    chord(cell: Cell) {
        if (cell.status === "clear") {
            let flags = 0;
            this.board.getPeerCells(cell).forEach((el) => {
                if (el.status === "flag") flags++;
            });

            this.board.getPeerCells(cell).forEach((el) => {
                if (el.status !== "flag" && flags === cell.proximityMines)
                    this.checkCell(el);
            });
        }
    }

    handleMouseOver(e: any, cell: Cell) {
        if (this.hoveringCell && cell.status !== "flag") {
            this.getCellValue(cell)?.htmlCell.classList.add("hovering");

            if (this.leftMouseDown && this.rightMouseDown) {
                this.getCellValues(cell).forEach((htmlCellEl) => {
                    const flag =
                        this.board
                            .getPeerCells(cell)
                            .find(
                                (el) =>
                                    el.column == htmlCellEl.c &&
                                    el.row == htmlCellEl.r
                            )?.status === "flag";

                    if (!flag) htmlCellEl.htmlCell.classList.add("hovering");
                });
            }

            this.hoveringCell = cell;
        }
    }

    handleMouseLeave(e: any, cell: Cell) {
        if (this.hoveringCell) {
            this.getCellValue(cell)?.htmlCell.classList.remove("hovering");

            this.getCellValues(cell).forEach((el) => {
                el.htmlCell.classList.remove("hovering");
            });
        }
    }

    handleMouseUpDoc(e: MouseEvent) {
        if (this.hoveringCell)
            this.getCellValue(this.hoveringCell)?.htmlCell.classList.remove(
                "hovering"
            );

        if (e.button === 2) {
            this.rightMouseDown = false;
        }

        if (e.button === 0) {
            this.leftMouseDown = false;
        }

        this.hoveringCell = null;
    }

    checkCell(cell: Cell) {
        if (cell.status !== "flag")
            this.getCellValue(cell)?.htmlCell.classList.add("hovering");

        const result = this.board.checkCell(cell);

        if (!this.gameStarted && !this.gameOver) {
            this.timer.startTimer();

            this.gameStarted = true;
        }

        if (result === "gameover") {
            this.gameOver = true;
            this.gameStarted = false;
            this.timer.stopTimer();

            this.getCellValue(cell)?.htmlCell.classList.add("clicked-mine");
        } else if (result === "win") {
            this.gameOver = true;
            this.gameStarted = false;
            this.timer.stopTimer();
        }
    }

    flag(cell: Cell) {
        if (cell.status !== "clear") {
            if (cell.status === "flag") {
                cell.status = "open";
            } else {
                cell.status = "flag";
            }
        }
    }

    newGame() {
        this.timer.clearTimer();
        this.gameStarted = false;
        this.gameOver = false;
        this.board = new Board(this.height, this.width, this.mines);
    }
}
